import { KIND_OF } from "general/enum";
const kindOf = [
  {
    value: 0,
    label: "Tất cả",
  },
  {
    value: KIND_OF.Cheat,
    label: "Lừa đảo",
  },
  {
    value: KIND_OF.Reputation,
    label: "Uy tín",
  },
];

export default kindOf;
